import React, { useEffect, useState } from "react";
import { FAQ } from "../../ApiServices/FAQ/FAQApi";
import batuk from "../../assets/images/new/fav-removebg-preview.png";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    GetFAQListData();
  }, []);

  const GetFAQListData = async () => {
    // debugger;
    try {
      const response = await FAQ();
      if (response) {
        if (response?.ResponseCode == 0) {
          if (response?.DATA) {
            const List = response?.DATA || [];
            setFaqs(List);
          }
        } else {
          console.error(response?.data?.errorMessage);
        }
      }
    } catch (error) {
      // setLoader(false);
      console.log(error);
    }
  };

  const FaqObj = [
    {
      question: "What services does Myomnamo offer?",
      answer:
        "Myomnamo offers a range of spiritual services, including Puja and Darshan bookings. You can book experienced Pandits for rituals or arrange daily pujas. Our E-Store provides access to religious goods, and Puja samgari, puja kits. Additionally, Myom namo offers horoscope services.",
      color: "#ffa415",
    },
    {
      question: "Can I schedule a temple visit through My OmNamo?",
      answer:
        "Yes, Myomnamo allows you to book temple visits online for your convenience. You can easily select your preferred temple, date, and time through our app. My Om Namo specializes in offering customized puja packages and online Darshans.",
      color: "#fc5528",
    },
    {
      question: "Can I book personalized consultations with spiritual mentors?",
      answer:
        "Yes, Myomnamo provides a platform to book personalized consultations with experienced spiritual mentors. You can connect with mentors who can offer guidance and support on your spiritual journey. These sessions can provide personalized advice and practices tailored to your specific needs",
      color: "#8139e7",
    },
    // {
    //   question: "Why is it important How Volunteer?",
    //   answer:
    //     "There are many variations of passages the majority have suffered alteration in some form injected humour, or randomised words.",
    //   color: "#399be7",
    // },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq-one faq-one--page">
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="sec-title text-center">
          <h6
            className="sec-title__tagline bw-split-in-right"
            style={{ perspective: "400px" }}
          >
            {/* <img className=" tagline-img" src={batuk} alt="" /> FAQ */}
            <img className=" tagline-img" src={batuk} alt="" /> FAQ
            {/* <span className="sec-title__tagline__border"></span> FAQ */}
          </h6>
          <h3
            className="sec-title__title bw-split-in-left"
            style={{ perspective: "400px" }}
          >
            Frequently Asked Questions.
          </h3>
        </div>
        <div className="row">
          <div className="col-xl-2"></div>
          <div className="col-xl-8">
            <div className="faq-one__accordion careox-accordion">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className={`accrodion ${activeIndex === index ? "active" : ""
                    }`}
                  style={{ "--accent-color": faq.color }}
                >
                  <div
                    className="accrodion-title"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4>
                      <span
                        className="accrodion-title__number"
                        style={{ marginRight: "10px", fontWeight: "500" }}
                      ></span>
                      {faq.QUESTION}
                      <span className="accrodion-title__icon"></span>
                    </h4>
                  </div>
                  <div
                    className="accrodion-content"
                    style={{
                      maxHeight: activeIndex === index ? "200px" : "0",
                      overflow: "hidden",
                      transition: "max-height 0.5s ease-in-out",
                    }}
                  >
                    <div className="inner">
                      <p>{faq.ANSWER}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xl-2"></div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
