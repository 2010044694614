import React, { useState } from "react";
import contactImg1 from "../../assets/images/shapes/contact-1-shape-2.png";
import contactImg2 from "../../assets/images/new/4.jpg";
import contactImg3 from "../../assets/images/new/2.jpg";
import contactImg4 from "../../assets/images/new/conatus us.png";
import contactImg5 from "../../assets/images/shapes/about-1-shape-2.png";
import contactImg6 from "../../assets/images/shapes/about-1-shape-3.png";
import { ERROR_MESSAGES } from "../../Components/GlobalErrorMessage";
import GoogleMap from "../../Components/GoogleMapModal";
import ContactUsImg from "../../assets/images/new/ContactUsImg.png";
import batuk from "../../assets/images/new/fav-removebg-preview.png";
import { Helmet } from "react-helmet-async";

const ContactPage = () => {
  const [contactData, setContactData] = useState({
    firstname: null,
    lastname: null,
    email: null,
    mobileNo: null,
    message: null,
  });
  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(null);

  function onCaptchaChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }

  const handleContactFormSubmit = () => {
    let isValid = false;

    if (
      contactData.name === "" ||
      contactData.name === undefined ||
      contactData.name === null ||
      contactData.mobileNo === "" ||
      contactData.mobileNo === undefined ||
      contactData.mobileNo === null ||
      contactData?.mobileNo?.length === 10 ||
      contactData.message === "" ||
      contactData.message === undefined ||
      contactData.message === null ||
      contactData.email === "" ||
      contactData.email === undefined ||
      contactData.email === null
    ) {
      isValid = true;
      setError(true);
    } else if (
      contactData.email !== null &&
      !validateEmail(contactData.email)
    ) {
      isValid = true;
      setError(true);
    }

    const api_params = {
      name: contactData.name,
      email: contactData.email,
      phoneNo: contactData.mobileNo,
      contactMsg: contactData.message,
    };

    if (!isValid) {
      ContactUsAPI(api_params);
    }
  };

  const ContactUsAPI = (api_params) => {
    console.log("api_params", api_params);
  };

  //   const ContactUsAPI = (params) => {
  //     const templateParams = {
  //       Name: params.name,
  //       Email: params.email,
  //       "Mobile Number": params.mobileNo,
  //       message: params.message,
  //       //   to_name: "Velvet Admin",
  //       //   from_name: "Velvet mail service",
  //       //   receiver_email: "velvetgps@gmail.com",
  //     };

  //     emailjs
  //       .send(
  //         "service_wp19bkh", // Service ID
  //         "template_b9wrsrm", // Template ID
  //         templateParams, // Template Parameters
  //         "e5YUU5XlmWckFjZk_" // Public Key
  //       )
  //       .then(
  //         () => {
  //           console.log("SUCCESS!");
  //           setShowThanksModal(true);
  //         },
  //         (error) => {
  //           console.log("FAILED...", error.text);
  //         }
  //       );
  //   };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  return (
    <>
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">Contact Us</h2>
        </div>
      </section>

      <Helmet>
        <title>Contact Us for Spiritual Services - MyOmNamo</title>
        <meta
          name="description"
          content="Have questions or need assistance? Reach out to us for pandit booking, puja arrangements, and spiritual guidance. We’re here to help!"
        />
        <link rel="canonical" href="https://www.myomnamo.com/contact-us" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Contact Us for Spiritual Services - MyOmNamo"
        />
        <meta
          property="og:description"
          content="Have questions or need assistance? Reach out to us for pandit booking, puja arrangements, and spiritual guidance. We’re here to help!"
        />
        <meta
          property="og:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />
        <meta property="og:url" content="https://www.myomnamo.com/contact-us" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="MyOmNamo" />
        <meta property="og:locale" content="en_IN" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contact Us for Spiritual Services - MyOmNamo"
        />
        <meta
          name="twitter:description"
          content="Have questions or need assistance? Reach out to us for pandit booking, puja arrangements, and spiritual guidance. We’re here to help!"
        />
        <meta
          name="twitter:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />
      </Helmet>

      <section
        className="contact-one contact-one--page"
        style={{ padding: "120px 0 0 0" }}
      >
        <div
          className="contact-one__shape"
          style={{
            backgroundImage: `url("${contactImg1}")`,
          }}
        ></div>
        <div
          className=""
          style={{ display: "flex", flexDirection: "column", gap: "5rem" }}
        >
          <div className="row">
            <div className="col-xl-5">
              <div
                className="about-one__image wow fadeInLeft"
                data-wow-delay="100ms"
                style={{ paddingLeft: "0px" }}
              >
                <div className="about-one__image__one">
                  <img
                    src={ContactUsImg}
                    style={{
                      height: "565px",
                      width: "467px",
                      objectFit: "cover",
                    }}
                    alt="contact"
                  />
                </div>
                {/* <div className="about-one__image__two">
                  <img
                    src={contactImg3}
                    style={{
                      height: "304px",
                      width: "262px",
                      objectFit: "cover",
                    }}
                    alt="contact"
                  />
                </div> */}
                <div className="about-one__image__shape-one">
                  {/* <img src={contactImg4} alt="shape" /> */}
                </div>
                <div className="about-one__image__shape-two">
                  {/* <img src={contactImg5} alt="shape" /> */}
                </div>
                <div className="about-one__image__shape-three">
                  {/* <img src={contactImg6} alt="shape" /> */}
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="contact-one__form form-one wow fadeInUp">
                <div className="sec-title text-left">
                  <h6 className="sec-title__tagline bw-split-in-right">
                    <img className=" tagline-img" src={batuk} alt="" /> Get in
                    Touch with Us
                  </h6>
                  <h3 className="sec-title__title bw-split-in-left">
                    Let’s Talk! Contact Us Today
                  </h3>
                </div>
                <p className="contact-one__text">
                  We’re here to answer any questions you have and help you get
                  started on your spiritual journey. Reach out and let’s
                  connect!
                </p>
                <div className="form-one__group">
                  <div className="form-one__control">
                    <input
                      type="text"
                      value={contactData.firstname}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove spaces completely
                        inputValue = inputValue.replace(/\s/g, "");

                        // Capitalize the first letter
                        if (inputValue.length > 0) {
                          inputValue =
                            inputValue.charAt(0).toUpperCase() +
                            inputValue.slice(1);
                        }

                        setContactData((prev) => ({
                          ...prev,
                          firstname: inputValue,
                        }));
                      }}
                      className="form-control"
                      placeholder="First Name*"
                      maxLength={20}
                    />

                    {error &&
                    (contactData.firstname === null ||
                      contactData.firstname === undefined ||
                      contactData.firstname === "") ? (
                      <span
                        style={{
                          color: "red",
                          fontFamily: "Ubuntu",
                          fontSize: "15px",
                        }}
                      >
                        {ERROR_MESSAGES}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-one__control">
                    <input
                      type="text"
                      value={contactData.lastname}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove spaces completely
                        inputValue = inputValue.replace(/\s/g, "");

                        // Capitalize the first letter
                        if (inputValue.length > 0) {
                          inputValue =
                            inputValue.charAt(0).toUpperCase() +
                            inputValue.slice(1);
                        }

                        setContactData((prev) => ({
                          ...prev,
                          lastname: inputValue,
                        }));
                      }}
                      className="form-control"
                      placeholder="Last Name"
                      maxLength={20}
                    />

                    {/* {error &&
                    (contactData.lastname === null ||
                      contactData.lastname === undefined ||
                      contactData.lastname === "") ? (
                      <span
                        style={{
                          color: "red",
                          fontFamily: "Ubuntu",
                          fontSize: "15px",
                        }}
                      >
                        {ERROR_MESSAGES}
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="form-one__control">
                    <input
                      type="email"
                      value={contactData.email}
                      onChange={(e) => {
                        setError(false);
                        let InputValue = e.target.value;

                        if (InputValue.startsWith(" ")) {
                          InputValue = InputValue.trimStart(); // Remove leading space
                        }

                        setContactData((prev) => ({
                          ...prev,
                          email: InputValue,
                        }));
                      }}
                      className="form-control"
                      placeholder="Email*"
                      maxLength={70}
                    />
                    {error &&
                    (contactData.email === null ||
                      contactData.email === undefined ||
                      contactData.email === "") ? (
                      <span
                        style={{
                          color: "red",
                          fontFamily: "Ubuntu",
                          fontSize: "15px",
                        }}
                      >
                        {ERROR_MESSAGES}
                      </span>
                    ) : contactData.email !== null &&
                      contactData.email !== "" &&
                      !validateEmail(contactData.email) ? (
                      <span style={{ color: "red" }}>
                        Please enter a valid email
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-one__control">
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone No*"
                      maxLength={10}
                      value={contactData.mobileNo}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        setContactData((prev) => ({
                          ...prev,
                          mobileNo: numericValue,
                        }));
                      }}
                      className="form-control"
                    />
                    {error &&
                    (contactData.mobileNo === null ||
                      contactData.mobileNo === undefined ||
                      contactData.mobileNo === "") ? (
                      <span
                        style={{
                          color: "red",
                          fontFamily: "Ubuntu",
                          fontSize: "15px",
                        }}
                      >
                        {ERROR_MESSAGES}
                      </span>
                    ) : contactData.mobileNo !== null &&
                      contactData?.mobileNo.length !== 10 ? (
                      <span style={{ color: "red" }}>
                        Invalid Mobile Number
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-one__control form-one__control--full">
                    <textarea
                      type="text"
                      className="form-control"
                      rows="5"
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Prevent first character from being a space
                        if (inputValue.startsWith(" ")) {
                          inputValue = inputValue.trimStart();
                        }

                        // Replace multiple spaces with a single space
                        inputValue = inputValue.replace(/\s{2,}/g, " ");

                        // Allow only letters, numbers, and spaces
                        inputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "");

                        setContactData((prev) => ({
                          ...prev,
                          message: inputValue,
                        }));
                      }}
                      value={contactData.message}
                      placeholder="Your Message*"
                      maxLength={200}
                    ></textarea>
                    {error &&
                    (contactData.message === null ||
                      contactData.message === undefined ||
                      contactData.message === "") ? (
                      <span
                        style={{
                          color: "red",
                          fontFamily: "Ubuntu",
                          fontSize: "15px",
                        }}
                      >
                        {ERROR_MESSAGES}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-one__control form-one__control--full">
                    <button
                      type="submit"
                      className="careox-btn"
                      onClick={handleContactFormSubmit}
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 w-100"
              style={{ padding: "0px", marginTop: "10px" }}
            >
              <GoogleMap className="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPage;
