/* global $ */
import React, { createContext, useEffect, useState, useCallback } from "react";
import Loader from "../Components/Loader";

const initialState = {
  loading: false,
  userID: null,
};

export const AuthContextProvider = createContext(initialState);
export const LoaderContext = createContext(initialState);

const AuthContext = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("userID")
  );

  return (
    <AuthContextProvider.Provider
      value={{

        isAuthenticated,
        setIsAuthenticated,
      }}
    >
      {children}

    </AuthContextProvider.Provider>
  );
};
const LoaderProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const [activeCategory, setActiveCategory] = useState('All');
  return (
    <LoaderContext.Provider value={{
      loader, setLoader, activeCategory, setActiveCategory
    }}>
      {children}
      {loader && <Loader />}
    </LoaderContext.Provider>
  );
};
export { LoaderProvider };
export default AuthContext;
