import React, { useContext, useEffect, useState } from "react";
import { DailyPanditBookingAPI } from "../../ApiServices/DailyPanditAPI/DailyPanditApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import Batuk from "../../assets/images/new/Batuk2.png";
import { BlogCategoryList, CurrentBlogData } from "../../ApiServices/BlogApi/BlogAPI";
import { Helmet } from "react-helmet-async";
import { LoaderContext } from "../../AuthContext/AuthContext";

const CommonBlogPage = () => {
  const user_id = localStorage.getItem("userID");
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [blogList, setblogList] = useState([]);
  const [dailyPanditPujaListData, setDailyPanditPujaListData] = useState([]);
  const [currentBlog, setCurrentBlog] = useState([]);
  const [searchBtnClick, setSearchClicked] = useState(false);

  const navigate = useNavigate();
  const { setLoader, loader, activeCategory, setActiveCategory } = useContext(LoaderContext)
  const { slug } = useParams();

  const [categories, setCategories] = useState([])
  // const [activeCategory, setActiveCategory] = useState('All');

  // Simulated items to filter
  const items = [
    { id: 1, name: 'Caveman Item', category: 'Caveman' },
    { id: 2, name: 'Voodoo Item', category: 'Voodoo' },
    { id: 3, name: 'Fury Item', category: 'Fury' },
    { id: 4, name: 'Iceman Item', category: 'Iceman' },
    { id: 5, name: 'Another Caveman', category: 'Caveman' },
  ];

  useEffect(() => {
    GetCategories()
    GetCurrentBlog({
      START: 0,
      END: 100,
      WORD: "",
      EXTRA1: "",
      EXTRA2: "",
      EXTRA3: "",
      LANG_ID: "1",
      USER_ID: user_id,
    });
  }, []);

  useEffect(() => {
    if (searchBtnClick) {
      GetCurrentBlog({
        START: 0,
        END: 100,
        WORD: searchKeyWord,
        EXTRA1: "",
        EXTRA2: "",
        EXTRA3: "",
        LANG_ID: "1",
        USER_ID: user_id,
        BlogCatID: activeCategory === 'All' ? null : activeCategory
      });
      setSearchClicked(false)
    }

  }, [searchBtnClick]);

  const GetCategories = async () => {
    setLoader(true);
    const response = await BlogCategoryList();

    if (response?.ResponseCode === "0" && response?.ResponseMessage === "list") {
      setLoader(false);
      const categories = response?.DATA;
      const formattedStateList = [
        { value: 'All', label: 'All' },
        ...categories.map((value) => ({
          value: value.BlogCatID,
          label: value.BlogCatName,
        })),
      ];
      setCategories(formattedStateList);

    } else {
      setLoader(false);
    }
  };

  const GetCurrentBlog = async (params) => {
    // debugger;
    setLoader(true);

    const response = await CurrentBlogData(params);

    if (response.ResponseCode === "0" && response.ResponseMessage === "list") {
      setLoader(false);
      const blogs = response?.DATA;
      setblogList(blogs);
      setCurrentBlog(blogs.find((item) => item.Blog_Slug === slug));
      //   if (blogs.length > 0) {
      //     if (location.state) {
      //       setCurrentBlog(location.state.blog);
      //     } else {
      //       setCurrentBlog(blogs[0]); // Set the first blog as the current blog
      //     }
      //   }
    } else {
      setLoader(false);
    }
  };
  const filteredBlogs = blogList.filter(
    (data) =>
      data.BLOG_TITLE.toLowerCase().includes(searchKeyWord.toLowerCase()) &&
      data.BLOG_TITLE !== currentBlog?.BLOG_TITLE
  );


  const stripHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || "";
  };

  const viewDetail = (puja) => {
    console.log("Navigating to details with puja:", puja);
    navigate("/services/daily-booking/puja-details", {
      state: { puja: puja },
    });
  };



  const handleCategoryClick = (category) => {
    setActiveCategory(category.value);
    setSearchClicked(true)
  };

  console.log('categories', categories)
  return (
    <>
      <section className="page-header" style={{ marginBottom: "2rem" }}>
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">
            Spiritual Gyan - Facts & Beliefs
          </h2>
        </div>
      </section>
      <Helmet>
        {/* Meta Title & Description */}
        <title>Spiritual Gyan - Insights, Rituals & Divine Knowledge</title>
        <meta
          name="description"
          content="Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance."
        />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.myomnamo.com/spiritual-gyan" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Spiritual Gyan - Insights, Rituals & Divine Knowledge"
        />
        <meta
          property="og:description"
          content="Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance."
        />
        <meta
          property="og:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.myomnamo.com/spiritual-gyan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="MyOmNamo" />
        <meta property="og:locale" content="en_IN" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Spiritual Gyan - Insights, Rituals & Divine Knowledge"
        />
        <meta
          name="twitter:description"
          content="Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance."
        />
        <meta
          name="twitter:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />

        {/* Schema Markup - Breadcrumbs */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.myomnamo.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Spiritual Gyan",
                item: "https://www.myomnamo.com/spiritual-gyan",
              },
            ],
          })}
        </script>

        {/* WebPage Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Spiritual Gyan - Insights, Rituals & Divine Knowledge",
            url: "https://www.myomnamo.com/spiritual-gyan",
            description:
              "Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance.",
            publisher: {
              "@type": "Organization",
              name: "MyOmNamo",
            },
          })}
        </script>
      </Helmet>
      <div
        className="container mt-4"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {/* Search Bar */}
        <div className="row mb-4">
          <div className="col-md-6 mx-auto">
            <div className="search-box"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: "1",
                maxWidth: "100%",
                marginRight: "10px",
              }}>
              <input
                type="text"
                className=" custom-search-input"
                placeholder="Search blogs...."
                value={searchKeyWord}
                onChange={(e) => setSearchKeyWord(e.target.value)}
              />
              <button
                className="input-group-text text-white"
                style={{ backgroundColor: "#a52444" }}
                onClick={() => {
                  setActiveCategory('All')
                  setSearchClicked(true)
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="category-tabs-container">
          <ul className="nav justify-content-center border-bottom">
            {categories.map((cat, idx) => (
              <li className="nav-item " key={idx}>
                <button
                  className={`nav-link-blog category-tab-btn ${activeCategory === cat.value ? 'active' : 'InActive'} `}
                  onClick={() => handleCategoryClick(cat)}
                >
                  {cat.label}
                </button>
              </li>
            ))}
          </ul>
          <br></br>
          {/* Optional: filtered content placeholder */}
          {/* <div className="mt-3">
            Showing results for: <strong>{activeCategory}</strong>
          </div> */}
        </div>
        {/* Puja Cards */}
        <div className="row mt-2">
          {blogList?.length > 0 ? blogList.map((blog, index) => (
            <div
              className="col-lg-4 col-md-6 col-sm-6 mb-4"
              key={index}
            // onClick={() => viewDetail(blog)}
            >
              <Link to={`/spiritual-gyan/${blog.Blog_Slug}`}>
                <div className="card card-shadow-sty shadow-sm border-0 h-100">
                  <img
                    src={blog.BLOG_IMAGE}
                    className="card-img-top"
                    alt={blog.DP_NAME}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = placeholderImg; // Set fallback image
                    }}
                    style={{ height: "200px", objectFit: "fill" }}
                  />
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5 className="card-title" style={{ color: "#a52444" }}>
                      {blog.BLOG_TITLE}
                    </h5>
                    <p className="card-text">
                      {stripHtml(blog.BLOG).length > 80
                        ? `${stripHtml(blog.BLOG).substring(0, 80)}...`
                        : stripHtml(blog.BLOG)}
                    </p>
                    {/* <span className="badge bg-danger">{blog.DP_DISC}% Off</span> */}
                    <div className="mt-3 text-end">
                      <span
                        // className="btn btn-sm"
                        style={{
                          // backgroundColor: "#a52444",
                          color: "#a52444",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        View Details <i className="icon-right-arrow"></i>
                      </span>
                      {/* <Link
                          to={`/spiritual-gyan/${blog.Blog_Slug}`}
                          className="btn btn-sm"
                          style={{
                            // backgroundColor: "#a52444",
                            color: "#a52444",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                          }}
                        >
                          View Details <i className="icon-right-arrow"></i>
                        </Link> */}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))
            : !loader && (
              <div className="d-flex flex-column align-items-center justify-content-center vh-90 text-center mt-4">
                <img
                  src={Batuk}
                  alt="Empty Cart"
                  className="img-fluid mb-3"
                  style={{ maxWidth: "200px" }}
                />
                <h5 className="fw-bold">No services available for this category</h5>
                <p className="text-muted">Please explore our other categories.</p>
              </div>
            )}



        </div>
      </div>
    </>
  );
};

export default CommonBlogPage;
