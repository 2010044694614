import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Cart from "./Cart";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import CheckedLoggedInModal from "./CheckLoggedInModal";
import toast from "react-hot-toast";
import about1 from "../../assets/images/about1.png";
import "./cart.css";
import { AuthContextProvider } from "../../AuthContext/AuthContext";
import LoginPopUp from "../LoginPopup";
import { Tooltip } from "@mui/material";
const Cart = () => {
  const data = localStorage.getItem("userID");
  console.log(data, "vvvv34");
  const [loginPopUp, setLoginPopUp] = useState(null);
  const { isAuthenticated } = useContext(AuthContextProvider);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const [inputValue, setInputValue] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const ItemAddedToast = () => toast.success("Item added successfully");
  const [images, setImages] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0);
  // const array =
  //   [
  //     'https://th.bing.com/th/id/R.14b7a75099d9165bd136d14025909730?rik=1Z%2fHIvCC3zOn4w&riu=http%3a%2f%2faphrozodiac.co%2fcdn%2fshop%2fproducts%2fmanifestation_crystal_set.jpg%3fv%3d1631996356&ehk=PC1euv1jCljQrXoNwRHsr%2btEPtMWR%2fQLAHe7EvyR80w%3d&risl=&pid=ImgRaw&r=0',
  //     'https://cdn.shopify.com/s/files/1/1042/6996/products/wealth-abundance-ritual-kit-abode-111_1200x.jpg?v=1657908943',
  //     'https://th.bing.com/th/id/OIP.B1vEdjB4kZBxWWe3ylbkgwHaDz?w=1833&h=941&rs=1&pid=ImgDetMain',

  //   ]

  useEffect(() => {
    setImages(location?.state?.PRODUCT_IMG)
  }, [])

  useEffect(() => {
    if (images?.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % images.length);
      }, 5000); // 5 seconds

      return () => clearInterval(interval); // Clear on unmount
    }
  }, [images.length]);



  // const displayValue = (e) => {
  //     setInputValue(e.target.value);
  // };


  const handlePrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };
  const handleIncrement = () => {
    if (inputValue < 20) {
      const newValue = inputValue + 1;
      setInputValue(newValue);
      updateProductQuantity(newValue);
    }
  };

  const handleDecrement = () => {
    if (inputValue > 1) {
      const newValue = inputValue - 1;
      setInputValue(newValue);
      updateProductQuantity(newValue);
    }
  };


  // Input change handler
  // const handleInputChange = (e) => {
  //   const filteredValue = e.target.value.replace(/[^0-9]/g, "");

  //   // Handle empty input case
  //   if (filteredValue === "") {
  //     setInputValue(1);
  //     return;
  //   }

  //   const numericValue = parseInt(filteredValue, 10);

  //   // Clamp value between 1-20
  //   const clampedValue = Math.min(Math.max(numericValue, 1), 20);

  //   setInputValue(clampedValue);
  // };

  const handleInputChange = (e) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, "");
    if (filteredValue === "") {
      setInputValue(1);
      return;
    }
    const numericValue = parseInt(filteredValue, 10);
    const clampedValue = Math.min(Math.max(numericValue, 1), 20);
    setInputValue(clampedValue);
    updateProductQuantity(clampedValue);
  };

  // const addProduct = (product) => {


  //   if (data) {
  //     axios
  //       .post(`${BaseUrl}/Estore_Cart`, {
  //         USER_ID: data,
  //         CART_ID: product?.CAT_ID,
  //         PRODUCT_ID: product?.PRODUCT_ID,
  //         QUANTITY: inputValue,
  //         TASK: "NEW",
  //       })
  //       .then((res) => {
  //         if (res.data.ResponseCode === "0") {
  //           toast.success(res.data.ResponseMessage);
  //           navigate("/view-cart");
  //         } else {
  //           ItemAddedToast();
  //           setTimeout(() => navigate("/view-cart"), 100);

  //           const currentCount = parseInt(localStorage.getItem("cartItemCount")) || 0;
  //           localStorage.setItem("cartItemCount", currentCount + parseInt(inputValue));

  //           // ✅ Dispatch event to update header
  //           window.dispatchEvent(new Event("cartUpdated")); // Notify other components
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error adding product to cart:", error);
  //         alert("An error occurred while adding the item to the cart.");
  //       });
  //   } else {
  //     navigate("/login");
  //     setIsModalOpen(true);
  //   }
  // };

  const addProduct = (product) => {

    if (!isAuthenticated) {
      setLoginPopUp(true)
      return
    }
    if (data) {
      axios
        .post(`${BaseUrl}/Estore_Cart`, {
          USER_ID: data,
          CART_ID: product?.CAT_ID,
          PRODUCT_ID: product?.PRODUCT_ID,
          QUANTITY: inputValue, // Quantity is only for API, not cart count
          TASK: "NEW",
        })
        .then((res) => {
          if (res.data.ResponseCode === "0") {
            toast.success(res.data.ResponseMessage);
            navigate("/view-cart");
          } else {
            ItemAddedToast();
            setTimeout(() => navigate("/view-cart"), 100);
          }

          // ✅ Get existing cart data (array of products)
          let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

          // ✅ Check if product is already in the cart
          const existingItem = cartItems.find(
            (item) => item.PRODUCT_ID === product.PRODUCT_ID
          );

          if (!existingItem) {
            // ✅ If new, add to cart and update count
            cartItems.push({ PRODUCT_ID: product.PRODUCT_ID });

            // ✅ Save updated cart array
            localStorage.setItem("cartItems", JSON.stringify(cartItems));

            // ✅ Update count in localStorage
            localStorage.setItem("cartItemCount", cartItems.length);

            // ✅ Notify header to update count
            window.dispatchEvent(new Event("cartUpdated"));
          }
        })
        .catch((error) => {
          console.error("Error adding product to cart:", error);
          alert("An error occurred while adding the item to the cart.");
        });
    } else {
      navigate("/login");
      setIsModalOpen(true);
    }
  };


  const updateProductQuantity = (quantity) => {
    axios
      .post(`${BaseUrl}/Estore_Cart`, {
        USER_ID: data,
        CART_ID: location.state.CAT_ID,
        PRODUCT_ID: location.state.PRODUCT_ID,
        QUANTITY: quantity,
        TASK: "EDIT",
      })
      .then((res) => {
        console.log(res.data);
        window.dispatchEvent(new Event("cartUpdated"));
      })
      .catch((err) => console.error("Error updating quantity:", err));
  };



  return (
    <>
      <div className="container mt-4">
        <div className="row">
          {/* Left Side - Sticky Image with Full Utilization */}
          <div className="col-md-5">
            <div className="slider-container" style={{ overflow: 'hidden', width: '100%', height: '90%' }}>
              <div
                className="slider-wrapper"
                style={{
                  display: 'flex',
                  transition: 'transform 0.5s ease-in-out',
                  transform: `translateX(-${currentIndex * 100}%)`,
                  height: '100%',
                }}
              >
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="img-fluid rounded"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      flexShrink: 0,
                    }}
                  />
                ))}

              </div>

            </div>
            {images?.length > 1 && (
              <div className="mt-3 d-flex gap-3 justify-content-center">
                <Tooltip title='Previous Image'>

                  <button className="input-group-text text-white" style={{ backgroundColor: "#a52444" }} onClick={handlePrev}>
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                  </button>
                </Tooltip>
                <Tooltip title='Next Image'>
                  <button className="input-group-text text-white" style={{ backgroundColor: "#a52444" }} onClick={handleNext}>
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </button>
                </Tooltip>

              </div>
            )}

          </div>

          {/* Right Side - Scrollable Product Details */}
          <div
            className="col-md-7 d-flex flex-column"
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            <h3>{location?.state?.PRODUCT_NAME}</h3>
            <h4 style={{ color: "#A42642" }}>
              ₹{" "}
              {location?.state?.PRODUCT_PRICE -
                Math.round(
                  (location?.state?.PRODUCT_PRICE * location?.state?.DISCOUNT) /
                  100
                )}
              {location?.state?.DISCOUNT !== 0 && (
                <span
                  className="text-muted ms-2"
                  style={{
                    fontWeight: "normal",
                    textDecoration: "line-through",
                    fontSize: "16px",
                  }}
                >
                  ₹ {location?.state?.PRODUCT_PRICE}
                </span>
              )}
            </h4>

            <p>
              <span style={{ background: "#8f3246" }} className="badge ">
                Discount {location?.state?.DISCOUNT}%
              </span>
            </p>

            {/* Size Selection */}
            <div
              className="mt-3 d-flex"
              style={{ justifyContent: "flex-start" }}
            >
              {/* <div className="d-flex align-items-center">
                <button
                  style={{ background: "#8f3246" }}
                  className="btn text-white fw-bold px-3"
                  onClick={handleDecrement}
                  disabled={inputValue === 1}
                >
                  -
                </button>
                <input
                  className="form-control text-center mx-2"
                  style={{ width: "60px" }}
                  value={inputValue}
                  onInput={handleInputChange}
                  readOnly
                />
                <button
                  style={{ background: "#8f3246" }}
                  className="btn text-white fw-bold px-3"
                  onClick={handleIncrement}
                  disabled={inputValue === 20}
                >
                  +
                </button>
              </div> */}
              <button
                className="btn text-white w-10 mx-3"
                style={{ background: "#8f3246" }}
                onClick={() => addProduct(location.state)}
              >
                Add to Cart
              </button>
            </div>

            {/* Product Details */}
            <div className="mt-3">
              <h6>Product Details</h6>
              {location?.state?.Height !== '' || location?.state?.Width !== '' || location?.state?.Length !== '' || location?.state?.Weight !== '' ? (
                <ul>
                  <h6> Dimensions :</h6>
                  {
                    location?.state?.Height !== '' && location?.state?.Height !== null && location?.state?.Height !== undefined ? (
                      <p>- Height : {location?.state?.Height || 0}</p>
                    ) : ''
                  }
                  {
                    location?.state?.Width !== '' && location?.state?.Width !== null && location?.state?.Width !== undefined ? (
                      <p>- Width : {location?.state?.Width || 0}</p>
                    ) : ''
                  }
                  {
                    location?.state?.Length !== '' && location?.state?.Length !== null && location?.state?.Length !== undefined ? (
                      <p>- Length : {location?.state?.Length || 0}</p>
                    ) : ''
                  }


                  {
                    location?.state?.Weight !== '' && location?.state?.Weight !== null && location?.state?.Weight !== undefined ? (
                      <p>- Weight : {location?.state?.Weight || 0}</p>
                    ) : ''
                  }
                </ul>
              ) : ""}


              <ul>
                <li
                  dangerouslySetInnerHTML={{
                    __html: location?.state?.PRODUCT_DESC,
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: location?.state?.PRODUCT_SPECI,
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
        {loginPopUp && (
          <LoginPopUp show={loginPopUp} onHide={() => setLoginPopUp(false)} />
        )}
      </div>
    </>
  );
};

export default Cart;
