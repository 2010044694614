import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { Tooltip } from "@mui/material";
import Batuk from "../../assets/images/new/Batuk2-removebg-preview.png";
import ComingSoonModal from "../ComingSoonModal";
import { LoaderContext } from "../../AuthContext/AuthContext";

const MyStore = () => {
  const [mydata, setMydata] = useState([]);
  const [Product, setProduct] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false)
  const [noElement, setNoElement] = useState(12);
  const [catId, setCatId] = useState(null);
  const [selectedCatId, setSelectedCatId] = useState(null);
  const navigate = useNavigate();
  const { setLoader, loader, activeCategory, setActiveCategory } = useContext(LoaderContext)
  useEffect(() => {
    setLoader(true)
    axios
      .get(`${BaseUrl}/ProductCategory`)
      .then((res) => {
        const responseData = res.data.DATA || [];
        setLoader(false)
        setMydata(responseData);
        console.log(responseData);
        setCatId(responseData[0].CAT_ID);
        setSelectedCatId(responseData[0].CAT_ID);
      })
      .catch((err) => {
        setLoader(false)
        console.log(err);
      });
    // setShowModal(true)
  }, []);

  useEffect(() => {
    // debugger;
    setLoader(true)
    const data = localStorage.getItem("userID") || "";
    axios
      .post(
        `${BaseUrl}/HomeWebservice?CAT_ID=${catId}&COUNTRY_ID=1&USER_ID=${data}`,
        {
          START: 1,
          END: 1000000000,
          WORD: "None",
        }
      )
      .then((res) => {
        const responseData = res.data.DATA;
        setLoader(false)
        const allProducts = [];
        responseData.forEach((category) => {
          if (Array.isArray(category.PRODUCT_LIST)) {
            category.PRODUCT_LIST.forEach((product) => {
              allProducts.push(product);
            });
          }
        });
        setProduct(allProducts);
      })
      .catch((err) => {
        setLoader(false)
        console.log(err);
      });
  }, [catId]);

  const Slice = Product.slice(0, noElement);
  const viewMore = (e) => {
    e.preventDefault();
    setNoElement((prevNoElement) => prevNoElement + 12);
  };
  console.log("selectedCatId", selectedCatId);
  const addToCart = (product) => {
    navigate("/cart", { state: product });
  };

  // useEffect(() => {
  //   if (Array.isArray(mydata) && mydata.length > 0) {
  //     setCatId(mydata[0].CAT_ID);
  //     setSelectedCatId(mydata[0].CAT_ID);
  //   }
  // }, [mydata]);

  const handleClear = () => {
    setSearch("");
  };
  const handleCategoryChange = (value) => {
    setCatId(value);
    setSelectedCatId(value);
  };
  return (
    <>
      <section style={{ background: "#f1f3f7" }}>
        <div className="container">
          <div className="container-fluid py-4">
            <div className="row">
              {/* Sidebar */}
              <div
                className="left col-md-3 col-sm-4 "
              // style={{ marginLeft: "10px" }}
              >
                <h5
                  className=" custom-list-item clearfix"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "20px",
                    borderBottom: "1px solid lightgray",
                    textTransform: "none",
                  }}
                >
                  Product Categories
                </h5>
                <ul className="margin-bottom-25 sidebar-menu">
                  {mydata?.length > 0 ? (
                    mydata.map((category, index) => (
                      <li
                        className="list-group-item clearfix"
                        // style={{ padding: "10px 15px" }}
                        key={category.CAT_ID}
                      >
                        <Link
                          to={`/my-store/${category.CAT_ID}`}
                          onClick={() => handleCategoryChange(category.CAT_ID)}
                          className={`d-flex align-items-center text-decoration-none ${selectedCatId === category.CAT_ID
                            ? "fw-bold #baf8f3"
                            : "text-dark"
                            }`}
                        >
                          {/* <img
                      src={category.PUJACAT_ICON}
                      alt={category.PUJACAT_NAME}
                      className="puja-icon"
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                        marginRight: "10px",
                        borderRadius: "50%",
                      }}
                    /> */}
                          <i className="fa fa-angle-right me-2"></i>
                          {/* <span>{category.CAT_NAME}</span> */}
                          {category.CAT_NAME?.length > 30 ? (
                            <Tooltip title={category.CAT_NAME}>
                              {`${category.CAT_NAME.substring(0, 19)}...`}
                            </Tooltip>
                          ) : (
                            category.CAT_NAME
                          )}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="list-group-item">No categories available</li>
                  )}
                </ul>
              </div>
              {/* Main Content */}
              <main className="col-md-9 col-sm-8">
                {/* Search Bar */}
                <div className="mb-4 custom-search">
                  <input
                    type="text"
                    className="custom-search-input"
                    placeholder="Search Product"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>

                {/* Product Grid */}
                <div
                  className="row "
                  style={{ minHeight: "50vh", justifyContent: "left" }}
                >
                  {Slice?.filter((product) =>
                    search
                      ? product.PRODUCT_NAME.toLowerCase().includes(
                        search.toLowerCase()
                      )
                      : true
                  ).length > 0 ? (
                    Slice.filter((product) =>
                      search
                        ? product.PRODUCT_NAME.toLowerCase().includes(
                          search.toLowerCase()
                        )
                        : true
                    ).map((product, index) => (
                      <div
                        key={index}
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6 rounded"
                      >
                        <div
                          className="card shadow-sm product-card"
                          onClick={() => addToCart(product)}
                          style={{
                            cursor: "pointer",
                            maxWidth: "250px",
                            border: "none",
                          }}
                        >
                          <div
                            className="card-img-container"
                            style={{ height: "200px", overflow: "hidden" }}
                          >
                            <img
                              src={product.PRODUCT_IMG[0]}
                              alt={product.PRODUCT_NAME}
                              className="card-img-top"
                              style={{ height: "100%", objectFit: "contain" }}
                            />
                          </div>
                          <div
                            className="card-body p-1 d-flex flex-column"
                            style={{ width: "13rem" }}
                          >
                            <h6
                              className="card-title text-truncate"
                              style={{ maxWidth: "100%", fontWeight: "normal" }}
                            >
                              {product.PRODUCT_NAME}
                            </h6>
                            <p className="card-text mb-2">
                              <span
                                className=""
                                style={{ color: "#a52444", fontSize: "20px" }}
                              >
                                Rs{" "}
                                {product.PRODUCT_PRICE -
                                  Math.round(
                                    (product.PRODUCT_PRICE * product.DISCOUNT) /
                                    100
                                  )}
                              </span>
                              {/* <span
                              className="text-muted ms-2"
                              style={{ fontSize: "20px" }}
                            >
                              <s>Rs {product.PRODUCT_PRICE}</s>
                            </span> */}
                            </p>
                            <div className="d-flex justify-content-between align-items-center">
                              <span
                                style={{ background: "#8f3246" }}
                                className="badge"
                              >
                                {product.DISCOUNT}% Off
                              </span>
                              <button
                                className="btn btn-outline-dark btn-sm"
                                style={{
                                  // border: "1px solid #a52444", // Custom outline color
                                  color: "white", // Text color
                                  backgroundColor: "#a52444", // Transparent background
                                  // borderRadius: "5px", // Slightly rounded corners
                                  // padding: "5px 10px", // Adjust padding for better spacing
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="col-12 d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px" }}
                    >
                      {search ? (
                        <div className="text-center">
                          <img src={Batuk} alt="" />
                          <h5 className="text-muted">No products found</h5>
                          <button
                            className="btn btn-outline-secondary mt-3"
                            onClick={handleClear}
                          >
                            Clear Search
                          </button>
                        </div>
                      ) :
                        !loader && (
                          <div className="d-flex flex-column align-items-center justify-content-center vh-90 text-center mt-4">
                            <img
                              src={Batuk}
                              alt="Empty Cart"
                              className="img-fluid mb-3"
                              style={{ maxWidth: "200px" }}
                            />
                            <h5 className="fw-bold">
                              No products available for this catagory
                            </h5>
                            <p className="text-muted">
                              Please explore our other categories.
                            </p>
                            {/* <Link to="/my-store">
                    <button
                      style={{ background: "#a52444" }}
                      className="text-white btn me-2"
                    >
                      Add Products
                    </button>
                  </Link> */}
                          </div>
                        )

                      }
                    </div>
                  )}
                </div>

                {/* View More Button */}
                {noElement < Product.length && (
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className="btn btn-warning px-4 py-2"
                      onClick={viewMore}
                    >
                      View More Products
                    </button>
                  </div>
                )}
              </main>
            </div>
          </div>
        </div>
      </section>
      {/* <ComingSoonModal show={showModal} onClose={(() => navigate('/'))} /> */}
    </>

  );
};

export default MyStore;
